<template>
    <div class='message_order'>
        <div class="message_order_head">
            <p><span>全部</span><small>点击下方查看彩种短信指令</small></p>
            <ul class="message_order_head_items">
                    <li 
                        :class="{'red_white': index == currentIndex}" 
                        @click="currentIndex = index"
                        class="message_order_head_items_item" v-for="(item, index) in titles" :key="index">
                        {{item.name}}
                    </li>
            </ul> 
        </div>
        <div class="message_order_messages">
            <p>{{currentTitle.name}}<small>可复制到短信快速编辑</small></p>
            <div 
                class="message_order_messages_message" 
                v-for="(message, index) in orders" 
                :key="index">
                    <div class="message_order_messages_message_left">
                        {{message.name}}
                    </div>
                    <div class="message_order_messages_message_right">
                        <p>
                                <span class="bold">指令格式：</span>
                                <span class="red">{{message.order}}</span>
                                <span class="copy" @click="copy(message.order)">发短信</span>
                                <small>{{message.tips}}</small></p>
                        <p><span class="bold">示例：</span>{{message.example || '无'}}</p>
                    </div>
                </div>
        </div>
        <Footer/>
    </div>
</template>
<script lang='ts'>
import { computed, onBeforeUnmount, reactive, toRefs } from 'vue'
import { messageOrder, lotteryNameMap, ssqGameId } from '../common-data'
import { jumpTo, setSession, getSession, getUserInfo } from '../utils/util'
import Footer from '../components/Footer.vue'
export default {
    name: '',
    components: {Footer},
    setup() {
         const state= reactive({
             currentIndex: '0',
             inWhiteList: false
        })
        state.currentIndex = getSession('index') || '0'
        // console.log(messageOrder)
        const titles = messageOrder.filter(ele => ele.name != '').map(ele => {
             return {
                name: ele.name, title: ele.title, tip: ele.tip
            }
        })
        
        const currentTitle = computed(() => {
            return titles[state.currentIndex]
        })
        const orders = computed(() => {
            return messageOrder[state.currentIndex].orders
        }) 
        async function loadData() {
            try {
                const info = await getUserInfo()
                state.inWhiteList = info.open == 1
            } catch(e) {
                console.error(e)
            }
        }
        loadData()
        function copy() {
            if (!state.inWhiteList) {
                return
            }
            const lottery = lotteryNameMap.get(currentTitle.value.name)
            console.log(lottery)
            const gameID = lottery&&lottery.id || ssqGameId
            jumpTo(`/choosenumber?gameId=${gameID}`)
        }
        onBeforeUnmount(() => {
            setSession('index', state.currentIndex)
        })
        return {
            ...toRefs(state),
            titles,
            orders,
            currentTitle,
            copy
        }
    },
}
</script>
<style lang='scss' scoped>
.red {
    color: $theme-dark;
    font-size: $font-big;
}
.bold {
    font-weight: 600;
}
.copy {
    color: $gray-middle;
    display: inline-block;
    color: #fff;
    background: $theme-dark;
    // border: solid 1px;
    border-radius: $box-radius-small;
    padding: 0px 5px;
    margin: 0 5px;
    font-size: $font-small;
}
.red_white {
    background-color: $theme-dark !important;
    color: #fff;
}
.message_order {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $bg-gray;
    overflow: auto;
    &_head {
        background: #fff;
        padding: $margin-big*2 $margin-big $margin-big;
        p {
            display: flex;
            justify-content: space-between;
            span {
                font-size: $font-big;
                font-weight: 600;
            }
            small {
                color: $gray-middle;
                font-size: $font-middle;
            }
            
        }
        &_items{
            display: flex;
            padding: 12px 0 10px 0;
            flex-wrap: wrap;
            &_item {
                background: #f3f3f3;
                margin:0 10px 10px 0;
                width: 31.3%;
                text-align: center;
                padding: 10px 0;
                border-radius: $box-radius-small;
                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }
    }
    &_messages {
        padding-bottom: 60px;
        &>p {
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            font-size: $font-big;
            padding: 20px $margin-big $margin-big*1.4 $margin-big;
            small {
                font-weight: 400;
                color: $gray-middle;
                font-size: $font-middle;
            }
        }
        &_message {
            border-top: solid $line-gray 1px;
            display: flex;
            align-items: center;
            position: relative;
            &_left {
                width: 20%;
                text-align: left;
                padding-left: 8px; 
                &::after{
                    position: absolute;
                    content: '';
                    top: 0;
                    bottom: 0;
                    left: 20%;
                    width: 1px;
                    background: $line-gray;
                }
            }
            &_right {
                width: 80%;
                padding: 5px 10px;
                word-break: break-all;
                
                p {
                    position: relative;
                    padding: 5px 0;
                    small {
                        color: $gray-middle;
                    }
                    &:first-child {
                        &::after{
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: -10px;
                            right: -10px;
                            height: 1px;
                            background: $line-gray;
                        }
                    }
                }
            }
        }
    }
}
</style>